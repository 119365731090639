import trackEventMixin from './trackEventMixin/trackEventMixin';
import { mapActions, mapState } from "vuex";
import segmentTracking from "../utilities/segmentTracking";
import { getBrowserTrackingContext } from "./../utilities/eventTrackingUtils";

export default {
  data() {
    return {
      experiments: {},
      placeholderImage: {
        url: '/images/my-account/default-picture.png',
        alt_text: 'Madison Reed Logo',
      },
    };
  },

  computed: {
    ...mapState('customer', ['cdata']),
    ...mapState('global', [
      'hideLogin',
    ]),
    ...mapState('constants', ['limitlessCodes']),
  },

  mixins: [trackEventMixin],

  methods: {
    ...mapActions('modal', [
      'showModal',
    ]),

    ...mapActions('cart', ['addToCart', 'addMultipleItemsToCart']),

    //- keeping trackEvent method for backwards compatability, though we should use mix_trackEvent
    trackEvent(eventName, properties) {
      return this.mix_trackEvent(eventName, properties);
    },

    trackCTA(path, ctaSource, newTab) {
      if (!path || !ctaSource) {
        return;
      }

      this.mix_trackEvent('CTA Clicked', {
        path: path,
        ctaSource: ctaSource,
      });

      this.goToPath(path, newTab);
    },

    trackNav(path, ctaSource, newTab) {
      if (!path || !ctaSource) {
        return;
      }

      this.mix_trackEvent('Nav Clicked', {
        path: path,
        ctaSource: ctaSource,
      });

      this.goToPath(path, newTab);
    },

    scrollTo(selector, animationDuration, delay, marginPx = 0) {
      animationDuration = animationDuration || 300;
      delay = delay || 10;

      setTimeout(function() {
        let el = document.querySelector(selector);
        if (el) {
          let startingY = window.pageYOffset;
          let elementY = startingY + el.getBoundingClientRect().top + marginPx;
          // If element is close to page's bottom then window will scroll only to some position above the element.
          let targetY = document.body.scrollHeight - elementY < window.innerHeight ? document.body.scrollHeight - window.innerHeight : elementY;
          let diff = targetY - startingY;
          let easing = function(t) { return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1; };
          let start;

          if (!diff) {
            return;
          }

          window.requestAnimationFrame(function step(timestamp) {
            if (!start) {
              start = timestamp;
            }
            let timeSinceStart = timestamp - start;
            let percentComplete = Math.min(timeSinceStart / animationDuration, 1);
            percentComplete = easing(percentComplete);
            window.scrollTo(0, startingY + diff * percentComplete);

            if (timeSinceStart < animationDuration) {
              window.requestAnimationFrame(step);
            }
          });
        }
      }, delay);
    },

    scrollElement(selector, targetTop, animationDuration, delay) {
      animationDuration = animationDuration || 300;
      delay = delay || 10;

      setTimeout(() => {
        let el = document.querySelector(selector);
        if (el) {
          let startingTop = el.scrollTop;
          let diff = startingTop - targetTop;
          let easing = function(t) { return t < .5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1; };
          let start;

          if (!diff) {
            return;
          }

          window.requestAnimationFrame(function step(timestamp) {
            if (!start) {
              start = timestamp;
            }
            let timeSinceStart = timestamp - start;
            let percentComplete = Math.min(timeSinceStart / animationDuration, 1);
            percentComplete = easing(percentComplete);
            el.scrollTop = targetTop + (diff * (1 - percentComplete));

            if (timeSinceStart < animationDuration) {
              window.requestAnimationFrame(step);
            }
          });
        }
      }, delay);
    },

    trackMREvent(eventName, properties) {
      properties = properties || {};
      properties.eventName = eventName;
      return this.trackEvent('MREvent', properties);
    },

    trackMREventAndRedirect(eventName, url, options) {
      if (eventName) {
        setTimeout(() => {
          this.trackMREvent(eventName, options);
        }, 300); // timeout so that track event gets a head start before redirect
      }

      window.location.href = url;
    },

    mix_removeUrlParameter(param) {
      if (!Object.keys(this.$route.query).includes(param)) {
        return;
      }

      const query = Object.assign({}, this.$route.query);
      delete query[param];
      this.$router.replace({ query });
    },

    goToPath(path, newTab) {
      if (newTab) {
        window.open(path);
      } else {
        location.href = path;
      }
    },

    back() {
      window.history.back();
    },

    trackSomethingElse(name) {
      segmentTracking.trackSomethingElse(name);
    },

    trackPageView() {
      const context = getBrowserTrackingContext();
      context.name = context.pageName;
      delete context.pageName;

      segmentTracking.trackSegmentPage(context.name, context);
    },

    runSegmentTracking() {
      if (this.cdata.trackExperiment !== undefined) {
        segmentTracking.trackSegmentEvent('Experiment Viewed', { experimentName: this.cdata.trackExperiment.experiment_name }).then(() => {
          this.trackPageView();
        });

        delete this.cdata.trackExperiment.recorded;
      } else {
        this.trackPageView();
      }
    },

    openLogInModal(eventName) {
      if (eventName) {
        this.trackMREvent(eventName);
      }
      let payload = {
        component: 'MrSignInV2Modal',
        theme: 'signin',
      };

      this.showModal(payload);
    },

    async addToCartWLimitlessPlusAndTrackEvent(limitlessPlusProduct, params) {
      const items = [params];
      const productId = limitlessPlusProduct?.product?.data?.id;
      if (!this.cdata.activeLimitlessPlus && productId) {
        items.push({
          product_id: productId,
          qty: 1,
          subscription: 52,
        });
      }
      await this.addMultipleItemsToCart({ items });
      if (params.eventName) {
        this.trackMREvent(params.eventName);
      }
      if (params.goToCart) {
        window.location = '/cart';
      }
    },
    //- We should always use "mix" for methods that are part of the mixin so that we can easily identify them
    mix_addToCartAndTrackEvent(params) {
      return this.addToCartAndTrackEvent(params, false);
    },

    //- Do not use this method, use mix_addToCartAndTrackEvent instead
    addToCartAndTrackEvent(params, deprecated = true) {
      if (deprecated) {
        // eslint-disable-next-line no-console
        console.warn('addToCartAndTrackEvent is deprecated. Use mix_addToCartAndTrackEvent instead');
      }
      return this.addToCart(params).then(() => {
        if (params.eventName) {
          this.trackMREvent(params.eventName);
        }
        if (params.goToCart) {
          window.location = '/cart';
        }
      });
    }
  },

  mounted() {
    this.experiments = window.experiments;
  },
};